import * as React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';

import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

import forskningsradet from '../assets/logos/forskningsradet.png';
import regionaleforskningsfond from '../assets/logos/regionaleforskningsfond.png';
import skattefunn from '../assets/logos/skattefunn.png';
import viken from '../assets/logos/viken.png';
import vtfk from '../assets/logos/vtfk.png';
import handle from '../assets/logos/handle.png';
import statsforvalteren from '../assets/logos/statsforvalteren.png';

import kerner from '../assets/Kerner.jpg';
import marianne from '../assets/marianne.png';
import tonje from '../assets/tonje.png';

// import {} from "../components/styles/services.css";

const transition_short = { duration: 0.2, ease: [0.6, 0.01, -0.05, 0.9] };
const transition_long = { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.9] };

const Tjenester = () => {
	return (
		<>
			<Layout pageTitle="Tjenester" background={true}>
				<div className="header" id="tjenester-header">
					<motion.div
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0 }}
						transition={transition_long}
					>
						<div
							className="container"
							style={{ paddingBottom: '0' }}
						>
							<div className="process__title">
								<h1 style={{ color: '#000' }}>Tjenester</h1>
								<h3 style={{ color: '#000' }}>
									Vi utvikler prosjekter, finner
									finansieringsordninger, skriver søknader, er
									prosjektledere, administrerer prosjekter og
									rapporterer.
								</h3>
							</div>
						</div>
						<div className="process" style={{ paddingTop: '0' }}>
							<div className="container">
								<ul className="process__grid">
									<li>
										<h3>Felt</h3>
										<p>
											Vi har spesialisert oss på arbeid
											innen sirkulærøkonomi og bærekraft,
											og har jobbet mye med
											landbruksbaserte næringer,
											idéutvikling, oppstart og FoU
											(Forskning og utvikling){' '}
										</p>
									</li>
									<li>
										<h3>Fleksibelt samarbeid</h3>
										<p>
											Grønn Kompetanse er et lite og
											fleksibelt selskap. Vi samarbeider
											med flere andre selskaper for å
											levere et godt resultat.
											<br />
											<br />
											Vi kan snu og tilpasse oss raskt, og
											tilby en bred faglig kompetanse.
										</p>
									</li>
									<li>
										<h3>Prosjektenes gang</h3>
										<p>
											Vi arbeider i tett dialog med
											oppdragsgiver og
											finansieringspartner. Vi har
											utarbeidet en arbeidsmodell som har
											gitt oss et meget godt tilslag og
											gjennomføringskraft i de prosjektene
											og oppgavene vi arbeider med.
											<br />
											<br />
											Derfor kan vi også skilte med over
											80 bevilgede og gjennomførte
											prosjekter siden 2011.
										</p>
									</li>
								</ul>
								<style jsx>{`
									.block-container {
										color: #fff;
										padding-top: calc(160px * var(--scale));
										padding-bottom: calc(
											120px * var(--scale)
										);
									}
									.block-cta-partners__posts {
										margin: 0 calc(-20px * var(--scale));
										padding: 0;
										list-style: none;
										display: -webkit-box;
										display: -ms-flexbox;
										display: flex;
										-ms-flex-wrap: wrap;
										flex-wrap: wrap;
										-webkit-box-align: center;
										-ms-flex-align: center;
										align-items: center;
										-webkit-box-pack: center;
										-ms-flex-pack: center;
										justify-content: center;
									}

									.block-cta-partners__posts.columns-4 li {
										width: 25%;
									}
									.block-cta-partners__posts li {
										padding: 0 calc(20px * var(--scale));
										margin-bottom: calc(
											40px * var(--scale)
										);
									}
									.block-cta-partners__title.center h2 {
										text-align: center;
									}
									.block-cta-partners__posts
										li
										.partner-link {
										-webkit-transition: all 0.2s ease;
										-o-transition: all 0.2s ease;
										transition: all 0.2s ease;
										-webkit-transition-delay: 0s;
										-o-transition-delay: 0s;
										transition-delay: 0s;
										display: -webkit-box;
										display: -ms-flexbox;
										display: flex;
										-webkit-box-align: center;
										-ms-flex-align: center;
										align-items: center;
										-webkit-box-pack: center;
										-ms-flex-pack: center;
										justify-content: center;
										text-align: center;
										height: calc(160px * var(--scale));
									}
									.partner-link img {
										filter: grayscale(1) invert(1);
									}
									.partners-section {
										background-image: url('../assets/purple.png');
									}
								`}</style>
								<section
									className="block-container bordered-container"
									id="partners-section"
									style={{ backgroundColor: '#000' }}
								>
									<div className="container">
										<section>
											<block
												className="block-cta-partners"
												id="cta-partners"
											>
												<div
													className="block-cta-partners__title center"
													style={{
														marginBottom:
															'calc(80px*var(--scale))',
													}}
												>
													<h2>
														Grønn Kompetanse har god
														erfaring med <br />
														prosjektfinansiering
														hos:
													</h2>
												</div>
												<ul className="block-cta-partners__posts columns-4">
													<li>
														<div className="partner-link">
															<img
																src={
																	regionaleforskningsfond
																}
																className="attachment-small size-small wp-post-image"
																alt=""
																loading="lazy"
																sizes="(max-width: 160px) 100vw, 160px"
																width={160}
																height={160}
															/>
														</div>
													</li>
													<li>
														<div className="partner-link">
															<img
																src={
																	forskningsradet
																}
																className="attachment-small size-small wp-post-image"
																alt=""
																loading="lazy"
																width={160}
																height={160}
															/>
														</div>
													</li>
													<li>
														<div className="partner-link">
															<img
																src={skattefunn}
																className="attachment-small size-small wp-post-image"
																alt=""
																loading="lazy"
																sizes="(max-width: 160px) 100vw, 160px"
																width={160}
																height={160}
															/>
														</div>
													</li>
													<li>
														<div className="partner-link">
															<img
																src={viken}
																className="attachment-small size-small wp-post-image"
																alt=""
																loading="lazy"
																sizes="(max-width: 160px) 100vw, 160px"
																width={160}
															/>
														</div>
													</li>
													<li>
														<div className="partner-link">
															<img
																src={vtfk}
																className="attachment-small size-small wp-post-image"
																alt=""
																loading="lazy"
																sizes="(max-width: 160px) 100vw, 160px"
																width={160}
															/>
														</div>
													</li>
													<li>
														<div className="partner-link">
															<img
																src={handle}
																className="attachment-small size-small wp-post-image"
																alt=""
																loading="lazy"
																sizes="(max-width: 160px) 100vw, 160px"
																width={160}
																style={{
																	filter: 'none',
																}}
															/>
														</div>
													</li>
													<li>
														<div className="partner-link">
															<img
																src={
																	statsforvalteren
																}
																className="attachment-small size-small wp-post-image"
																alt=""
																loading="lazy"
																sizes="(max-width: 160px) 100vw, 160px"
																width={160}
															/>
														</div>
													</li>
												</ul>
												<div className="show-more ng-star-inserted">
													<Link
														to="/nyheter"
														className="tdua white"
													>
														Igangsatte og
														gjennomførte prosjekter
														så langt
													</Link>
												</div>
											</block>
										</section>
										<p />
									</div>
								</section>

								<style jsx>{`
									.quote-component {
										padding-left: calc(40px * var(--scale));
										border-left: 2px solid;
									}
									.process__quote {
										max-width: calc(860px * var(--scale));
										margin: 0 auto;
										margin-top: calc(200px * var(--scale));
									}
									.quote-component__title {
										font-size: 1.25rem;
										margin-bottom: calc(
											20px * var(--scale)
										);
										letter-spacing: -0.5px;
									}
									.quote-component__person {
										display: flex;
										align-items: center;
										margin-top: calc(40px * var(--scale));
									}
									.quote-component__person__img img,
									.quote-component__person__img picture {
										-o-object-fit: cover;
										object-fit: cover;
										-o-object-position: center center;
										object-position: center center;
										display: block;
										height: calc(100px * var(--scale));
										width: calc(100px * var(--scale));
										border-radius: 50%;
										margin-right: calc(20px * var(--scale));
									}
									.quote-component__person__text__name {
										font-size: 1.25rem;
										font-weight: 600;
										letter-spacing: -0.5px;
										line-height: 1.4;
									}
									.quote-component__person__text__meta {
										line-height: 1.5;
										letter-spacing: -0.5px;
									}
								`}</style>
								{/* <div className="process__quote">
									<quote _nghost-lbi-c36>
										<div className="quote-component ng-star-inserted">
											<p className="quote-component__title ng-star-inserted">
												<b></b> — Biotext
											</p>

											<h3 className="quote-component__text ng-star-inserted">
												“Samarbeidet vi har hatt med
												Grønn Kompetanse har vært
												enestående. Grundig arbeid, og
												utrolige resulatater! De har
												hjulpet oss med
												prosjektveiledning og
												forskningssøknader.”
											</h3>

											<div className="quote-component__person ng-star-inserted">
												<div className="quote-component__person__img ng-star-inserted">
													<picture>
														<source
															srcSet={kerner}
														/>
														<img src={kerner} />
													</picture>
												</div>

												<div className="quote-component__person__text">
													<div className="quote-component__person__text__name ng-star-inserted">
														Karl Kerner
													</div>

													<div className="quote-component__person__text__meta">
														<span className="ng-star-inserted">
															—{' '}
														</span>

														<span className="ng-star-inserted">
															CEO Biotext AS
														</span>
													</div>
												</div>
											</div>
										</div>
									</quote>
								</div> */}
							</div>
						</div>
					</motion.div>
				</div>
				<section className="about-section" id="acta">
					<div className="container" id="contact-wrapper">
						<h2 style={{ color: 'black' }}>
							Vi gir råd, søker, administrerer, drifter og
							rapporter prosjekter og oppgaver.
						</h2>
						<h2 className="black-text">
							Disse samarbeider vi tett med.
						</h2>

						<div
							className="stripe"
							style={{ marginBottom: '2rem' }}
						></div>
						<div className="row-about">
							<div className="image">
								<img
									src={tonje}
									style={{ width: '60%' }}
									className="bordered-container"
								/>
							</div>
							<div className="text black-text">
								<h2>Tonje Bergh</h2>
								<p>
									<ul>
										<li>Driver selskapet Plantmania</li>
										<li>Utdannet planteviter ved NMBU </li>
										<li>
											Tilleggsutdanning innen Urbant
											landbruk fra NMBU
										</li>
										<li>
											10 år som leder av
											opplysningskontoret for Blomster og
											Planter
										</li>
									</ul>
									Kjernekompetanse:
									<ul>
										<li>
											Utarbeidet veileder for urbant
											landbruk
										</li>
										<li>
											Beplanting på betongdekker,
											vegetajon og spiselige prosukter
										</li>
										<li>
											Prosjektledelse i en rekke
											prosjekter
										</li>
										<li>Kompetanse innen Start-Ups</li>
										<li>
											Søknader til fylkeskommuner,
											Innovasjon Norge, forskningsrådet..
										</li>
										<li>Planteforedling</li>
										<li>Opplysningsarbeid og SoMe</li>
									</ul>
								</p>
							</div>
						</div>
						<div
							className="stripe"
							style={{ marginBottom: '2rem' }}
						></div>
						<div
							className="row-about"
							style={{
								marginBottom: 'calc(120px * var(--scale))',
								backgroundColor: '',
								flexDirection: 'row-reverse',
							}}
							id=""
						>
							<div className="image">
								<picture>
									<img
										src={marianne}
										style={{ width: '60%' }}
										className="bordered-container"
									/>
								</picture>
							</div>
							<div
								className="text black-text"
								style={{ paddingLeft: 0 }}
							>
								<h2>Marianne Strøm</h2>
								<p>
									<ul>
										<li>Driver selskapet Lilleskagen</li>
										<li>
											Utdannet planteviter ved NMBU
											(Norges Miljø og Biovitenskaplige
											Universitet)
										</li>
										<li>
											20 år som planterådgiver i Norsk
											Landbruksrådgivning
										</li>
										<li>
											Driver egen nettbutikk og agentur
										</li>
									</ul>
									Kjernekompetanse:
									<ul>
										<li>
											Konsulenttjenester og søknader til
											fylkeskommuner, Innovasjon Norge,
											forskningsrådet..
										</li>
										<li>Skattefunn</li>
										<li>
											Konsulent for private innen
											hageplanlegging
										</li>
										<li>
											Engasjement, deltakelse, styrearbeid
											i en rekke innovasjonsbedrifter
										</li>
										<li>Erfaring med Start Ups</li>
									</ul>
								</p>
							</div>
						</div>

						<div
							className="stripe"
							style={{ marginBottom: '2rem' }}
						></div>
						<div
							className="row-about"
							id=""
							style={{ backgroundColor: '' }}
						>
							<div className="image">
								<img
									src={kerner}
									className="bordered-container"
								/>
							</div>
							<div className="text black-text">
								<h2>Karl Kerner</h2>
								<p>
									<ul>
										<li>Driver selskapet Biotext</li>
										<li>
											Utdannet innen jordfag og
											mikrobiologi ved NMBU (Norges Miljø
											og Biovitenskaplige Universitet)
										</li>
									</ul>
									Kjernekompetanse:
									<ul>
										<li>
											Internasjonalt arbeid, forskning og
											klimatilpassing
										</li>
										<li>
											Prosjektkoordinering og
											internasjonale nettverk
										</li>
										<li>
											Søknader forskningsrådet,
											utredninger
										</li>
										<li>
											Fylkeskommuner, Innovasjon Norge,
											Skattefunn
										</li>
										<li>
											Oversettelse og språkvask (norsk,
											engelsk, tysk)
										</li>
									</ul>
								</p>
							</div>
						</div>

						<h2 className="black-text">
							Sammen med våre samarbeidspartnere skreddersyr vi
							gode løsninger tilpasset den enkeltes behov!
						</h2>
					</div>
				</section>
				<style jsx>
					{`
						.header {
							color: #000;
							opacity: 1;
							transform: translateY(0);
						}

						@media (max-width: 767px) {
							.header .container {
								// padding-top: calc(280px * var(--scale));
							}
						}
						.header .container .hero {
							padding-bottom: calc(190px * var(--scale));
						}
						.header .container .hero h1 {
							font-weight: 400;
							letter-spacing: -1px;
							line-height: 1.11;
							max-width: calc(1024px * var(--scale));
							margin: 0 auto;
							text-align: center;
						}
						@media (min-width: 768px) and (max-width: 991px) {
							.header .container .hero h1 {
								font-size: 4rem;
							}
						}
						@media (max-width: 767px) {
							.header .container .hero h1 {
								font-size: 2.5rem;
							}
						}
						.header .container .intro__service {
							text-align: center;
							max-width: 51.25rem;
							margin: 0 auto;
							margin-bottom: calc(150px * var(--scale));
						}
						@media (max-width: 767px) {
							.header .container .intro__service {
								margin-bottom: calc(180px * var(--scale));
							}
						}
						.header .container .intro__service:last-child {
							margin-bottom: 0;
						}
						.header .container .intro__service .img-wrapper img,
						.header
							.container
							.intro__service
							.img-wrapper
							picture {
							width: auto;
							max-width: 100%;
							margin: 0 auto;
						}
						@media (max-width: 1499px) {
							.header .container .intro__service .img-wrapper img,
							.header
								.container
								.intro__service
								.img-wrapper
								picture {
								max-width: 386px;
							}
						}
						@media (max-width: 767px) {
							.header .container .intro__service .img-wrapper img,
							.header
								.container
								.intro__service
								.img-wrapper
								picture {
								max-width: 100%;
							}
						}
						.header .container .intro__service > h2 {
							margin-top: calc(55px * var(--scale));
							font-weight: 400;
							color: #a6c1bf;
							letter-spacing: -1px;
							text-align: center;
							line-height: 1.16;
						}
						@media (max-width: 767px) {
							.header .container .intro__service > h2 {
								font-size: 2rem;
							}
						}
						.header .container .intro__service > h2 b {
							color: --var(gray);
							font-weight: 400;
						}
						.header.fade-out {
							opacity: 0;
							transform: translateY(-100px);
						}
						.header.static {
							background: #042825;
						}
						.content .container .wwd {
							color: #042825;
						}
						.content .container .wwd__title {
							padding-bottom: calc(80px * var(--scale));
							max-width: calc(820px * var(--scale));
						}
						.content .container .wwd__title h2 {
							font-weight: 400;
							letter-spacing: -1px;
							line-height: 1.16;
						}
						@media (max-width: 767px) {
							.content .container .wwd__title h2 {
								padding-top: calc(80px * var(--scale));
								font-size: 2rem;
							}
						}
						.content .container .wwd__item {
							display: block;
							transition: all 0.8s
								cubic-bezier(0.165, 0.84, 0.44, 1);
							opacity: 0;
							transform: translateY(100px) translateZ(0);
							padding-top: calc(120px * var(--scale));
							padding-bottom: calc(100px * var(--scale));
							border-bottom: 1px solid #042825;
						}
						@media (max-width: 767px) {
							.content .container .wwd__item {
								padding-bottom: calc(50px * var(--scale));
							}
							.content .container .wwd__item:not(.first) {
								padding-top: calc(140px * var(--scale));
							}
						}
						@media (min-width: 992px) {
							.content .container .wwd__item_desc {
								padding-left: 33.33%;
							}
							.content .container .wwd__item_desc_wrapper {
								padding-left: calc(20px * var(--scale));
							}
						}
						.content .container .wwd__item_desc h1 {
							font-weight: 400;
							letter-spacing: -1px;
							line-height: 1.06;
							margin-bottom: calc(25px * var(--scale));
						}
						@media (max-width: 767px) {
							.content .container .wwd__item_desc h1 {
								font-size: 3rem;
							}
						}
						.content .container .wwd__item_desc ul {
							margin: 0;
							margin-bottom: calc(40px * var(--scale));
							padding: 0;
							list-style: none;
							display: flex;
							align-items: center;
							flex-wrap: wrap;
						}
						.content .container .wwd__item_desc ul li {
							opacity: 0.5;
							font-size: 1.5rem;
							letter-spacing: -0.5px;
							line-height: 1.33;
							margin-right: calc(8px * var(--scale));
						}
						.content .container .wwd__item_desc ul li:before {
							content: '#';
						}
						.content .container .wwd__item_desc p {
							font-size: 1.5rem;
							letter-spacing: -0.5px;
							line-height: 1.3;
						}
						.content .container .wwd__item_projects {
							display: flex;
							margin: 0 calc(-20px * var(--scale));
						}
						@media (max-width: 767px) {
							.content .container .wwd__item_projects {
								flex-wrap: wrap;
							}
						}
						.content .container .wwd__item_projects project-card {
							padding: 0 calc(20px * var(--scale));
							width: 33.33%;
						}
						@media (min-width: 768px) and (max-width: 991px) {
							.content
								.container
								.wwd__item_projects
								project-card {
								width: 50%;
							}
							.content
								.container
								.wwd__item_projects
								project-card:last-child {
								display: none;
							}
						}
						@media (max-width: 767px) {
							.content
								.container
								.wwd__item_projects
								project-card {
								width: 100%;
							}
						}
						.content .container .wwd__item.active {
							opacity: 1 !important;
							transform: translateY(0) translateZ(0) !important;
						}
						.content .container .services {
							color: #042825;
							padding-bottom: calc(200px * var(--scale));
						}
						@media (max-width: 1199px) {
							.content .container .services {
								padding-bottom: calc(160px * var(--scale));
							}
						}
						@media (max-width: 767px) {
							.content .container .services {
								padding-bottom: calc(140px * var(--scale));
							}
						}
						.content .container .services > h1 {
							text-align: center;
							font-weight: 400;
							letter-spacing: -1px;
							line-height: 1.1;
							margin: calc(120px * var(--scale)) 0
								calc(80px * var(--scale)) 0;
						}
						@media (max-width: 767px) {
							.content .container .services > h1 {
								font-size: 3rem;
								margin-bottom: calc(40px * var(--scale));
							}
						}
						.content .container .services .services_wrapper {
							display: flex;
							flex-wrap: wrap;
							margin: 0 calc(-40px * var(--scale));
						}
						.content .container .services .service {
							flex: 0 0 50%;
							padding: calc(40px * var(--scale));
						}
						@media (max-width: 991px) {
							.content .container .services .service {
								flex: 0 0 100%;
							}
						}
						.content .container .services .service > h2 {
							font-weight: 400;
							letter-spacing: -1px;
							line-height: 1.16;
						}
						@media (max-width: 767px) {
							.content .container .services .service > h2 {
								font-size: 2.5rem;
							}
						}
						.content .container .services .service__text > p {
							font-weight: 400;
							font-size: 1.25rem;
							letter-spacing: -0.5px;
							line-height: 1.4;
							padding-top: calc(20px * var(--scale));
							padding-bottom: calc(40px * var(--scale));
						}
						.content .container .services .service__text > ul {
							margin: 0;
							padding: 0;
							list-style: none;
							font-size: 1.25rem;
							letter-spacing: -0.5px;
							line-height: 1.33;
						}
						@media (max-width: 767px) {
							.content .container .services .service__text > ul {
								font-size: 1.125rem;
							}
						}
						.content .container .services .service__text > ul li {
							padding-top: calc(7px * var(--scale));
							padding-bottom: calc(7px * var(--scale));
						}
						@media (max-width: 767px) {
							.content
								.container
								.services
								.service__text
								> ul
								li {
								padding-top: calc(3px * var(--scale));
								padding-bottom: calc(3px * var(--scale));
							}
						}
						.content
							.container
							.services
							.service__text
							> ul
							li:before {
							content: '#';
						}
						.content .container .services .services_images {
							padding-top: calc(80px * var(--scale));
						}
						.content .container .services .services_images img,
						.content .container .services .services_images picture {
							width: 100%;
							max-width: 100%;
							margin: 0 auto;
						}
						.content .testimonials {
							position: relative;
							background: #e6eded;
							padding-top: calc(120px * var(--scale));
							padding-bottom: calc(70px * var(--scale));
							margin-bottom: -5px;
							z-index: 10000;
						}
						.content .testimonials .container > h2 {
							margin-bottom: calc(120px * var(--scale));
							max-width: 820px;
							letter-spacing: -1px;
						}
						@media (max-width: 767px) {
							.content .testimonials .container > h2 {
								font-size: 2rem;
							}
						}
						.content .testimonials .container > ul {
							margin: 0;
							padding: 0;
							list-style: none;
						}
						.content .testimonials .container > ul li {
							padding: 0 calc(40px * var(--scale));
							border-left: 2px solid #042825;
							max-width: calc(900px * var(--scale));
							margin: 0 auto calc(100px * var(--scale)) auto;
						}
						@media (max-width: 767px) {
							.content .testimonials .container > ul li {
								padding-left: 20px;
								padding-right: 0;
							}
						}
						.content .testimonials .container > ul li > h3 {
							margin-bottom: calc(40px * var(--scale));
							letter-spacing: -1px;
							line-height: 1.22;
						}
						@media (max-width: 767px) {
							.content .testimonials .container > ul li > h3 {
								font-size: 1.5rem;
							}
						}
						.content .testimonials .container > ul li .client {
							display: flex;
							align-items: center;
						}
						.content
							.testimonials
							.container
							> ul
							li
							.client
							.image {
							width: 100px;
							height: 100px;
							min-width: 100px;
						}
						@media (max-width: 767px) {
							.content
								.testimonials
								.container
								> ul
								li
								.client
								.image {
								width: 70px;
								min-width: 70px;
								height: auto;
							}
						}
						.content
							.testimonials
							.container
							> ul
							li
							.client
							.image
							img,
						.content
							.testimonials
							.container
							> ul
							li
							.client
							.image
							picture {
							width: auto;
							max-width: 100%;
						}
						.content
							.testimonials
							.container
							> ul
							li
							.client
							.name {
							padding-left: calc(20px * var(--scale));
							font-weight: 400;
							font-size: 1rem;
							letter-spacing: -0.5px;
							line-height: 1.5;
							max-width: calc(400px * var(--scale));
						}
						@media (max-width: 767px) {
							.content
								.testimonials
								.container
								> ul
								li
								.client
								.name {
								line-height: 1.2;
							}
						}
						@media (min-width: 992px) {
							.content
								.testimonials
								.container
								> ul
								li
								.client
								.name {
								max-width: calc(300px * var(--scale));
							}
						}
						.content
							.testimonials
							.container
							> ul
							li
							.client
							.name
							> b {
							display: block;
							font-weight: 600;
							font-size: 1.25rem;
							letter-spacing: -0.5px;
							line-height: 1.4;
						}
						.content .testimonials .container .more-testimonials {
							text-align: center;
							padding-top: calc(20px * var(--scale));
							padding-bottom: calc(130px * var(--scale));
						}
						.content
							.testimonials
							.container
							.more-testimonials
							button {
							background: transparent;
							border: none;
							cursor: pointer;
							display: flex;
							align-items: center;
							margin: 0 auto;
							outline: none !important;
						}
						.content
							.testimonials
							.container
							.more-testimonials
							button:active,
						.content
							.testimonials
							.container
							.more-testimonials
							button:focus,
						.content
							.testimonials
							.container
							.more-testimonials
							button:hover {
							outline: none !important;
						}
						.content
							.testimonials
							.container
							.more-testimonials
							button
							span {
							font-size: 1.5rem;
							margin-left: 10px;
							letter-spacing: -0.5px;
						}
						.content
							.testimonials
							.container
							.more-testimonials.hidden {
							display: none;
						}
					`}
				</style>

				<style jsx>{`
					@charset "UTF-8";
					.header {
						color: #fff;
						opacity: 1;
						transform: translateY(0);
						transition: all 1.2s ease;
						transition-delay: 0s;
					}

					@media (max-width: 767px) {
						.header .container {
							padding-top: calc(280px * var(--scale));
						}
					}

					.header .container .hero {
						padding-bottom: calc(160px * var(--scale));
					}

					@media (max-width: 767px) {
						.header .container .hero {
							padding-bottom: calc(100px * var(--scale));
						}
					}

					.header .container .hero h1 {
						font-weight: 400;
						letter-spacing: -0.04em;
						line-height: 1.11;
						max-width: calc(1026px * var(--scale));
						margin: 0 auto 1.25rem;
						text-align: center;
					}

					@media (min-width: 768px) and(max-width: 991px) {
						.header .container .hero h1 {
							font-size: 3.875rem;
						}
					}

					@media (max-width: 767px) {
						.header .container .hero h1 {
							font-size: 2.75rem;
						}
					}

					.header .container .hero p {
						font-size: 1.5rem;
						line-height: 1.33;
						font-weight: 400;
						letter-spacing: -0.025em;
						text-align: center;
						color: #a6c1bf;
					}

					@media (max-width: 767px) {
						.header .container .hero p {
							font-size: 1.25rem;
						}
					}

					.header .container .intro__row {
						display: flex;
						justify-content: space-around;
					}

					@media (max-width: 991px) {
						.header .container .intro__row {
							flex-direction: column;
							align-items: center;
						}
					}

					.header .container .intro__col {
						max-width: calc(480px * var(--scale));
					}

					@media (max-width: 991px) {
						.header .container .intro__col {
							max-width: calc(780px * var(--scale));
						}
					}

					.header .container .intro__col:nth-child(2) {
						padding-top: calc(160px * var(--scale));
					}

					@media (max-width: 991px) {
						.header .container .intro__col {
							padding-top: calc(160px * var(--scale));
						}
					}

					.header .container .intro__col img,
					.header .container .intro__col picture {
						display: block;
						margin: 0 auto;
						max-width: 424px;
						max-height: 424px;
					}

					@media (max-width: 1499px) {
						.header .container .intro__col img,
						.header .container .intro__col picture {
							max-width: 386px;
							max-height: 386px;
						}
					}

					@media (max-width: 767px) {
						.header .container .intro__col img,
						.header .container .intro__col picture {
							max-width: 280px;
							max-height: 280px;
						}
					}

					.header .container .intro__col h3 {
						text-align: center;
						font-weight: 400;
						color: #a6c1bf;
						margin-top: calc(40px * var(--scale));
						line-height: 1.25;
						letter-spacing: -0.025em;
					}

					.header .container .intro__col h3 b {
						font-weight: 400;
						color: #fff;
					}

					.header.fade-out {
						opacity: 0;
						transform: translateY(-100px);
					}

					.header.static {
						background: #042825;
					}

					.wwd {
						color: #042825;
						padding-bottom: calc(200px * var(--scale));
					}

					.wwd__title {
						margin-bottom: calc(160px * var(--scale));
						max-width: calc(814px * var(--scale));
					}

					.wwd__title h2 {
						font-weight: 400;
						letter-spacing: -0.025em;
						line-height: 1.16;
					}

					@media (max-width: 767px) {
						.wwd__title h2 {
							font-size: 2rem;
						}
					}

					.wwd__item {
						display: flex;
						align-items: center;
					}

					.wwd__item:not(:last-child) {
						margin-bottom: calc(120px * var(--scale));
					}

					@media (max-width: 767px) {
						.wwd__item {
							flex-direction: column;
							align-items: start;
						}
					}

					.wwd__item_img {
						min-width: 50%;
						padding-right: 160px;
					}

					@media (min-width: 992px) and(max-width: 1199px) {
						.wwd__item_img {
							padding-right: 90px;
							min-width: 46%;
						}
					}

					@media (min-width: 768px) and(max-width: 991px) {
						.wwd__item_img {
							padding-right: 60px;
						}
					}

					@media (max-width: 767px) {
						.wwd__item_img {
							width: 100%;
							padding-right: 0;
						}
					}

					.wwd__item_img img,
					.wwd__item_img picture {
						display: block;
						max-width: 100%;
					}

					.wwd__item_text {
						max-width: calc(600px * var(--scale));
						padding-left: 20px;
					}

					@media (max-width: 767px) {
						.wwd__item_text {
							padding-left: 0;
							padding-top: 20px;
							max-width: 100%;
						}
					}

					.wwd__item_text h1 {
						font-weight: 400;
						line-height: 1.11;
						letter-spacing: -0.04em;
						margin-bottom: calc(20px * var(--scale));
					}

					@media (max-width: 1199px) {
						.wwd__item_text h1 {
							font-size: 3.875rem;
						}
					}

					@media (max-width: 991px) {
						.wwd__item_text h1 {
							font-size: 2.75rem;
						}
					}

					.wwd__item_text p {
						font-size: 1.5rem;
						line-height: 1.33;
						letter-spacing: -0.025em;
						margin-bottom: calc(40px * var(--scale));
					}

					@media (max-width: 991px) {
						.wwd__item_text p {
							font-size: 1.25rem;
						}
					}

					.wwd__item_text a {
						font-size: 1rem;
						line-height: 1.5;
						letter-spacing: -0.02em;
						padding: 0 calc(32px * var(--scale));
					}

					.wwd__quote {
						max-width: calc(860px * var(--scale));
						margin: 0 auto;
						margin-top: calc(200px * var(--scale));
					}

					@media (max-width: 991px) {
						.wwd__quote {
							max-width: 100%;
							padding: 0 20px;
						}
					}

					@media (max-width: 767px) {
						.wwd__quote {
							padding: 0;
						}
					}

					.capabilities {
						background-color: #e6eded;
						color: #042825;
						padding-top: calc(120px * var(--scale));
						padding-bottom: calc(200px * var(--scale));
					}

					.capabilities__title {
						margin-bottom: calc(80px * var(--scale));
					}

					.capabilities__title h1 {
						font-weight: 400;
						letter-spacing: -0.04em;
						line-height: 1.1;
						margin-bottom: calc(20px * var(--scale));
					}

					@media (max-width: 767px) {
						.capabilities__title h1 {
							font-size: 3rem;
						}
					}

					.capabilities__title p {
						font-size: 1.25rem;
						line-height: 1.4;
						letter-spacing: -0.5px;
						max-width: calc(500px * var(--scale));
					}

					@media (min-width: 992px) and(max-width: 1199px) {
						.capabilities__title p {
							max-width: calc(540px * var(--scale));
						}
					}

					@media (max-width: 767px) {
						.capabilities__title p {
							max-width: 100%;
						}
					}

					.capabilities__items {
						margin-bottom: calc(60px * var(--scale));
						margin-left: 33%;
						display: flex;
						flex-wrap: wrap;
					}

					@media (min-width: 992px) and(max-width: 1199px) {
						.capabilities__items {
							margin-left: 25%;
						}
					}

					@media (max-width: 991px) {
						.capabilities__items {
							margin-left: 0;
						}
					}

					.capabilities__items .capabilities__item {
						margin-bottom: calc(80px * var(--scale));
						flex: 0 0 50%;
					}

					@media (max-width: 767px) {
						.capabilities__items .capabilities__item {
							flex: 0 0 100%;
						}
					}

					.capabilities__items .capabilities__item h3 {
						line-height: 1.25;
						letter-spacing: -0.025em;
						margin-bottom: calc(10px * var(--scale));
					}

					.capabilities__items .capabilities__item ul {
						margin: 0;
						padding: 0;
						list-style: none;
					}

					.capabilities__items .capabilities__item ul li {
						font-size: 1.25rem;
						line-height: 1.4;
						letter-spacing: -0.02em;
					}

					.capabilities__items .capabilities__item ul li:before {
						content: '— ';
					}

					.capabilities__images {
						margin-bottom: calc(200px * var(--scale));
					}

					.capabilities__images img,
					.capabilities__images picture {
						width: 100%;
						max-width: 100%;
						margin: 0 auto;
					}

					.capabilities__quote {
						max-width: calc(860px * var(--scale));
						margin: 0 auto;
					}

					@media (max-width: 991px) {
						.capabilities__quote {
							max-width: 100%;
							padding: 0 20px;
						}
					}

					@media (max-width: 767px) {
						.capabilities__quote {
							padding: 0;
						}
					}

					.results {
						background-color: #042825;
						color: #fff;
						padding-top: calc(120px * var(--scale));
						padding-bottom: calc(200px * var(--scale));
					}

					.results__title {
						margin-bottom: calc(120px * var(--scale));
					}

					.results__title h1 {
						font-weight: 400;
						letter-spacing: -1px;
						line-height: 1.1;
						text-align: center;
					}

					@media (max-width: 767px) {
						.results__title h1 {
							font-size: 3rem;
						}
					}

					.results__grid {
						margin: 0 -20px;
						padding: 0;
						list-style: none;
						display: flex;
						flex-wrap: wrap;
					}

					.results__grid li {
						margin-bottom: calc(100px * var(--scale));
						flex: 0 0 33.33%;
						padding: 0 20px;
					}

					@media (min-width: 768px) and(max-width: 991px) {
						.results__grid li {
							flex: 0 0 50%;
						}
					}

					@media (max-width: 767px) {
						.results__grid li {
							flex: 0 0 100%;
						}
					}

					.results__grid li h2 {
						font-size: 6.25rem;
						line-height: 1.08;
						letter-spacing: -3px;
						margin-bottom: calc(20px * var(--scale));
						font-weight: 400;
					}

					@media (max-width: 767px) {
						.results__grid li h2 {
							font-size: 3rem;
						}
					}

					.results__grid li p {
						font-size: 1.25rem;
						line-height: 1.4;
						letter-spacing: -0.5px;
						color: #a6c1bf;
					}

					.results__grid li p a {
						color: #fff;
					}

					.results__quote {
						max-width: calc(860px * var(--scale));
						margin: 0 auto;
						margin-top: calc(100px * var(--scale));
					}

					@media (max-width: 991px) {
						.results__quote {
							max-width: 100%;
							padding: 0 20px;
						}
					}

					@media (max-width: 767px) {
						.results__quote {
							padding: 0;
						}
					}

					.process {
						color: #042825;
						padding-top: calc(120px * var(--scale));
					}

					.process__title {
						margin-bottom: calc(120px * var(--scale));
					}

					.process__title h1 {
						font-weight: 400;
						letter-spacing: -1px;
						line-height: 1.1;
						max-width: calc(600px * var(--scale));
					}

					@media (min-width: 768px) and(max-width: 991px) {
						.process__title h1 {
							max-width: calc(640px * var(--scale));
						}
					}

					@media (max-width: 767px) {
						.process__title h1 {
							font-size: 3rem;
						}
					}

					.process__grid {
						margin: 0 -20px;
						padding: 0;
						list-style: none;
						display: flex;
						flex-wrap: wrap;
					}

					.process__grid li {
						margin-bottom: calc(100px * var(--scale));
						flex: 0 0 33.33%;
						padding: 0 20px;
					}

					@media (min-width: 768px) and(max-width: 991px) {
						.process__grid li {
							flex: 0 0 50%;
						}
					}

					@media (max-width: 767px) {
						.process__grid li {
							flex: 0 0 100%;
						}
					}

					.process__grid li h3 {
						line-height: 1.25;
						letter-spacing: -1px;
						margin-bottom: calc(10px * var(--scale));
						font-weight: 400;
					}

					.process__grid li a,
					.process__grid li p {
						font-size: 1.25rem;
						line-height: 1.4;
						letter-spacing: -0.5px;
					}

					.process__grid li a {
						margin-top: calc(20px * var(--scale));
					}

					.process__quote {
						max-width: calc(860px * var(--scale));
						margin: 0 auto;
						margin-top: calc(100px * var(--scale));
					}

					@media (max-width: 991px) {
						.process__quote {
							max-width: 100%;
							padding: 0 20px;
						}
					}

					@media (max-width: 767px) {
						.process__quote {
							padding: 0;
						}
					}
				`}</style>
			</Layout>
			<style jsx>{`
				.video-large {
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background: #000;
					z-index: 9000000;
					padding: calc(46px * var(--scale));
				}
				.video-large,
				.video-large iframe {
					width: 100%;
					height: 100%;
				}
				.video-large .close {
					position: absolute;
					top: calc(20px * var(--scale));
					right: calc(20px * var(--scale));
					cursor: pointer;
					opacity: 0.8;
					transition: opacity 0.2s;
					background: none;
					border: none;
					padding: 0;
					margin: 0;
					outline: none !important;
				}
				.video-large .close:active,
				.video-large .close:focus,
				.video-large .close:hover {
					outline: none !important;
					opacity: 1;
				}
				.video-large .close i {
					position: relative;
					display: block;
					width: calc(24px * var(--scale));
					height: calc(24px * var(--scale));
				}
				.video-large .close i:after,
				.video-large .close i:before {
					content: '';
					top: 11px;
					left: 2px;
					position: absolute;
					width: 20px;
					height: 2px;
					transform: rotate(45deg);
					background-color: #fff;
				}
				.video-large .close i:after {
					transform: rotate(-45deg);
				}
				.intro {
					color: #546db0;
					opacity: 1;
					transform: translateY(0);

					padding-bottom: calc(100px * var(--scale));
					transition: all 1.2s ease;
					transition-delay: 0s;
				}
				.intro.fade-out {
					opacity: 0;
					transform: translateY(-100px);
				}
				.intro.static {
					background: #042825;
				}

				.intro .hero h1 {
					font-weight: 400;
					letter-spacing: -1px;
					line-height: 1.11;
					margin-bottom: 2.5rem;
					max-width: calc(1000px * var(--scale));
				}
				@media (max-width: 1199px) {
					.intro .hero h1 {
						font-size: 4.25rem;
						max-width: 100%;
					}
				}
				@media (max-width: 991px) {
					.intro .hero h1 {
						font-size: 3.25rem;
					}
				}
				@media (max-width: 767px) {
					.intro .hero h1 {
						font-size: 3rem;
					}
				}
				@media (max-width: 575px) {
					.intro .hero h1 br {
						display: none;
					}
				}
				.intro p {
					font-size: 1.5rem;
					line-height: 1.33;
					letter-spacing: -0.025em;
					max-width: calc(700px * var(--scale));
				}
				@media (min-width: 768px) and (max-width: 991px) {
					.intro p {
						font-size: 1.375rem;
					}
				}
				@media (max-width: 767px) {
					.intro p {
						font-size: 1.25rem;
					}
				}
				.intro .video {
					position: relative;
					height: 0;
					padding-top: 56.29228687%;
				}
				.intro .video .video-inner {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					cursor: pointer;
				}
				.intro .video .video-inner video {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					-o-object-position: center;
					object-position: center;
					font-family: 'object-fit: cover; object-position: center';
				}
				.intro .video .video-inner .video-overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: rgba(4, 40, 37, 0.25);
				}
				.intro .video .video-inner .video-play {
					z-index: 2;
				}
				.intro .video .video-inner .video-play,
				.intro .video .video-inner .video-play .circle {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: calc(120px * var(--scale));
					height: calc(120px * var(--scale));
				}
				.intro .video .video-inner .video-play .circle {
					border-radius: 50%;
					background: rgba(4, 40, 37, 0.7);
					-webkit-backdrop-filter: blur(8px);
					backdrop-filter: blur(8px);
					transform-origin: center center;
					transition: all 0.5s ease;
					transition-delay: 0s;
				}
				.intro .video .video-inner .video-play svg {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				.intro .video .video-inner .video-play:active .circle,
				.intro .video .video-inner .video-play:focus .circle,
				.intro .video .video-inner .video-play:hover .circle {
					width: calc(124px * var(--scale));
					height: calc(124px * var(--scale));
					background: rgba(4, 40, 37, 0.9);
				}
				.intro .partners {
					margin: 0;
					padding: 0;
					list-style: none;
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					padding-top: calc(120px * var(--scale));
				}
				@media (max-width: 767px) {
					.intro .partners {
						display: none;
					}
				}
				.intro .partners li {
					display: block;
				}
				@media (min-width: 1200px) {
					.intro .partners li {
						transition: all 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
						opacity: 0;
						transform: translateY(100px) translateZ(0);
					}
				}
				@media (min-width: 1200px) {
					.intro .partners li:nth-child(4n + 1) {
						transition-delay: 0.08s;
					}
					.intro .partners li:nth-child(4n + 2) {
						transition-delay: 0.165s;
					}
					.intro .partners li:nth-child(4n + 3) {
						transition-delay: 0.33s;
					}
					.intro .partners li:nth-child(4n + 4) {
						transition-delay: 0.495s;
					}
					.intro .partners li:nth-child(4n + 5) {
						transition-delay: 0.4s;
					}
				}
				@media (max-width: 767px) {
					.intro .partners li {
						margin: 20px;
					}
				}
				.intro .partners li.aos-animate {
					opacity: 1 !important;
					transform: translateY(0) translateZ(0) !important;
				}
				@media (min-width: 576px) {
					.intro .partners li:first-child img {
						height: calc(20px * var(--scale));
					}
				}
				@media (min-width: 576px) {
					.intro .partners li:nth-child(2) img {
						height: calc(48px * var(--scale));
					}
				}
				@media (min-width: 576px) {
					.intro .partners li:nth-child(3) img {
						height: calc(22px * var(--scale));
					}
				}
				@media (min-width: 576px) {
					.intro .partners li:nth-child(4) img {
						height: calc(40px * var(--scale));
					}
				}
				@media (min-width: 576px) {
					.intro .partners li:nth-child(5) img {
						height: calc(25px * var(--scale));
					}
				}
				.intro .about {
					padding-top: calc(200px * var(--scale));
					padding-bottom: calc(200px * var(--scale));
					padding-left: 26%;
				}
				@media (min-width: 992px) and (max-width: 1199px) {
					.intro .about {
						padding-left: 20%;
					}
				}
				@media (max-width: 991px) {
					.intro .about {
						padding-left: 0;
						padding-top: calc(120px * var(--scale));
						padding-bottom: calc(120px * var(--scale));
					}
				}
				.intro .about h2 {
					font-weight: 400;
					letter-spacing: -0.025em;
					line-height: 1.17;
				}
				@media (min-width: 992px) and (max-width: 1199px) {
					.intro .about h2 {
						font-size: 2.625rem;
					}
				}
				@media (max-width: 991px) {
					.intro .about h2 {
						font-size: 2.375rem;
					}
				}
				@media (max-width: 767px) {
					.intro .about h2 {
						font-size: 2rem;
					}
				}
				.intro .about h2 span {
					color: #2a2a2a;
				}
				.intro .about > a {
					font-size: 1.5rem;
					letter-spacing: -1px;
					line-height: 1.33;
				}
				.projects-list-title {
					padding-top: calc(120px * var(--scale));
					padding-bottom: calc(120px * var(--scale));
				}
				@media (max-width: 767px) {
					.projects-list-title {
						padding-top: calc(80px * var(--scale));
						padding-bottom: calc(80px * var(--scale));
					}
				}
				.projects-list-title .container h2 {
					font-weight: 400;
					color: #042825;
					letter-spacing: -1px;
					line-height: 1.15;
					margin-bottom: 1.25rem;
				}
				.projects-list-title .container h2 span {
					display: block;
				}
				@media (max-width: 767px) {
					.projects-list-title .container h2 {
						font-size: 2rem;
					}
				}
				.projects-list-title .container p {
					max-width: 490px;
					font-size: 1.25rem;
				}
				.clients-section .container {
					position: relative;
				}
				.clients-section .container:before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: calc(20px * var(--scale));
					right: calc(20px * var(--scale));
					height: 1px;
					background: #042825;
				}
				.clients-section .container > h2 {
					letter-spacing: -1px;
					line-height: 1.15;
					max-width: 600px;
					padding-top: calc(120px * var(--scale));
					margin-bottom: 1.25rem;
				}
				@media (max-width: 767px) {
					.clients-section .container > h2 {
						font-size: 2rem;
					}
				}
				.clients-section .container > p {
					max-width: 493px;
					padding-bottom: calc(80px * var(--scale));
					font-size: 1.25rem;
					line-height: 1.4;
					letter-spacing: -0.02em;
				}
				.insights-section {
					padding-bottom: calc(200px * var(--scale));
				}
				.insights-section .container {
					position: relative;
				}
				.insights-section .container:before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: calc(20px * var(--scale));
					right: calc(20px * var(--scale));
					height: 1px;
					background: #042825;
				}
				.about-section {
					position: relative;
					background: xc;
					color: #fff;
					padding-top: calc(120px * var(--scale));
				}
				.about-section .container > h1 {
					letter-spacing: -0.04em;
					line-height: 1.11;
					max-width: calc(850px * var(--scale));
					padding-bottom: calc(120px * var(--scale));
					text-align: center;
					margin: 0 auto;
				}
				.about-section .container > h2 {
					letter-spacing: -0.04em;
					line-height: 1.11;
					max-width: calc(850px * var(--scale));
					padding-bottom: calc(120px * var(--scale));
					text-align: center;
					margin: 0 auto;
				}

				@media (max-width: 1199px) {
					.about-section .container > h1 {
						font-size: 4.25rem;
					}
				}
				@media (max-width: 991px) {
					.about-section .container > h1 {
						font-size: 3.25rem;
					}
				}
				@media (max-width: 767px) {
					.about-section .container > h1 {
						font-size: 3rem;
					}
				}
				@media (max-width: 575px) {
					.about-section .container > h1 {
						font-size: 2.25rem;
					}
				}
				.about-section .row-about {
					margin-bottom: calc(120px * var(--scale));
				}
				@media (min-width: 768px) {
					.about-section .row-about {
						display: flex;
						align-items: center;
						margin-left: -1.25rem;
						margin-right: -1.25rem;
					}
				}
				.about-section .row-about .image {
					margin-bottom: 1.25rem;
				}
				@media (min-width: 768px) {
					.about-section .row-about .image {
						min-width: 50%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
						margin-bottom: 0;
					}
				}
				.about-section .row-about .image img,
				.about-section .row-about .image picture {
					display: block;
					max-width: 100%;
					width: 100%;
				}
				@media (min-width: 768px) {
					.about-section .row-about .text {
						min-width: 50%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
					}
				}
				@media (min-width: 992px) {
					.about-section .row-about .text {
						padding-left: calc(8.33333333% + 1.25rem);
					}
				}
				@media (max-width: 767px) {
					.about-section .row-about .text {
						padding-top: 20px;
					}
				}
				.about-section .row-faq {
					display: flex;
					flex-direction: column-reverse;
				}
				@media (min-width: 768px) {
					.about-section .row-faq {
						flex-direction: row;
						margin-left: -1.25rem;
						margin-right: -1.25rem;
					}
				}
				@media (min-width: 768px) {
					.about-section .row-faq .text {
						min-width: 50%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
					}
				}
				.about-section .row-faq .text h2 {
					margin-bottom: 1.25rem;
					letter-spacing: -0.025em;
					line-height: 1.18;
				}
				@media (min-width: 768px) and (max-width: 991px) {
					.about-section .row-faq .text h2 {
						font-size: 2.25rem;
					}
				}
				@media (max-width: 767px) {
					.about-section .row-faq .text h2 {
						font-size: 1.5rem;
					}
				}
				.about-section .row-faq .text p {
					max-width: 493px;
				}
				.about-section .row-faq .image {
					margin-bottom: 2.5rem;
					text-align: center;
				}
				@media (min-width: 768px) {
					.about-section .row-faq .image {
						min-width: 50%;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
						margin-bottom: 0;
					}
				}
				@media (min-width: 992px) {
					.about-section .row-faq .image {
						padding-left: calc(8.33333333% + 1.25rem);
					}
				}
				@media (max-width: 767px) {
					.about-section .row-faq .image {
						padding-top: 20px;
					}
				}
				.about-section a,
				.about-section p {
					font-size: 1.5rem;
					line-height: 1.33;
					letter-spacing: -0.02em;
				}
				@media (min-width: 768px) and (max-width: 991px) {
					.about-section a,
					.about-section p {
						font-size: 1.375rem;
					}
				}
				@media (max-width: 767px) {
					.about-section a,
					.about-section p {
						font-size: 1.25rem;
					}
				}
			`}</style>
		</>
	);
};

export default Tjenester;

export const Head = () => (
	<>
		<title>Tjenester — Grønn Næringskompetanse</title>

		<meta name="author" content="Bjørnar Bjelland" />
		<meta name="copyright" content="Grønn Næringskompetanse" />
		<meta name="robots" content="follow" />
		<meta name="language" content="Norwegian"></meta>
		<meta name="revisit-after" content="7 days"></meta>
		<meta
			name="description"
			content="Vi utvikler prosjekter, finner finansieringsordninger, skriver søknader, er prosjektledere, administrerer prosjekter og rapporterer."
		/>
		<meta
			name="keywords"
			content="Tjenester vi tilbyr, fagfelt, kompetanse"
		/>
	</>
);
